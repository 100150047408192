<template>
  <form @submit.prevent="saveAttachment" class="flex flex-wrap">
    <p>Schedule or record an event for this enquiry</p>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Attachment Name (optional)</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="attachment.name"
        placeholder="Attachment Name..."
        type="text"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Attachment Description (optional)</label>
      <textarea
        v-model="attachment.description"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Attachment Description..."
        rows="10"
      ></textarea>
    </div>
    <div v-if="state == 'Create'" class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Attachment File</label>
      <div
        @drop.prevent="onDropFiles"
        @dragover.prevent
        @change="onFilesChange"
        class="relative w-full bge-input rounded"
      >
        <input
          class="absolute w-full h-full opacity-0 cursor-pointer"
          placeholder="Choose a file to upload..."
          type="file"
        />
        <div class="flex items-center py-2 px-4">
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
            <path d="M14 2v6h6M12 18v-6M9 15h6" />
          </svg>
          <p v-if="!attachment.file">Click to Upload File</p>
          <p v-if="attachment.file">{{ attachment.file.name }}</p>
        </div>
      </div>
    </div>

    <div class="w-full">
      <button
        type="button"
        @click="saveAttachment"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save Attachment</span>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "AddEditAttachment",
  components: {},
  props: {
    attachment: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      state: "Create"
    };
  },
  mounted() {
    if (this.attachment.attachment_id) {
      this.state = "Edit";
    }
  },
  methods: {
    onDropFiles: function(e) {
      this.handleAttachments(e.dataTransfer.files);
    },
    onFilesChange: function(e) {
      this.handleAttachments(e.target.files);
    },
    handleAttachments: function(fileList) {
      if (fileList.length >= 1) {
        this.attachment.file = fileList[0];
        this.$forceUpdate();
      }
    },
    saveAttachment: function() {
      this.$emit("complete", this.attachment);
    }
  }
};
</script>